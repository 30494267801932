<template>
  <div>
    <div class="container mx-auto px-3">
      <div class="flex -mx-3">
        <div class="w-1/4">
          <div v-loading="isLoadingChannels">
            <div
              v-for="(channel) in channels"
              :key="channel.cid"
              @click="handleSelectedChannel(channel)"
            >
              <ChannelCard :channel="channel" />
            </div>
          </div>
        </div>
        <div class="w-3/4">
          <div v-if="selectedChannel">
            <ChannelDetail :channel="selectedChannel" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { StreamChat } from 'stream-chat'
import ChannelCard from '@/components/Chats/V2/ChannelCard'
import ChannelDetail from '@/components/Chats/V2/ChannelDetail'

export default {
  name: 'ChatPageV2',
  components: { ChannelCard, ChannelDetail },
  data () {
    return {
      chatMe: null,
      chatClient: this.$chatClient || null,
      channels: [],
      isLoading: false,
      isLoadingChannels: true,
      selectedChannel: null
    }
  },
  async created () {
    await this.getStreamInstance()
  },
  async mounted () {
    await this.getMyChannels()
  },
  destroyed () {
    this.chatClient.disconnectUser()
  },
  methods: {
    async getStreamInstance () {
      const self = this
      const client = StreamChat.getInstance('ge9kh3amuzs4')
      this.chatClient = client
      this.$chatClient = client

      const res = await client.connectUser(
        { id: String(this.$user.id) },
        async () => {
          // make a request to your own backend to get the token
          const response = await self.$rf
            .getRequest('DoctorRequest')
            .getStreamGenerationToken()
          return response.data?.token
        }
      )

      this.chatMe = res?.me || {}
    },
    async getMyChannels () {
      try {
        if (!this.chatClient) return

        const userId = this.chatMe?.id || String(this.$user.id) || ''
        const filter = { members: { $in: [userId] } }
        const sort = [{ last_message_at: -1 }]

        const res = await this.chatClient.queryChannels(filter, sort, {
          watch: true, // this is the default
          state: true
        })

        this.channels = [...res]
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoadingChannels = false
      }
    },
    handleSelectedChannel (channel) {
      this.selectedChannel = { ...channel }
    }
  }
}
</script>
