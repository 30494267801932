<template>
  <div>
    <div>{{ getChannelName }}</div>
    <div class="flex flex-column">
      <div
        class="w-auto mb-3"
        :class="isLocal(message) ? 'ml-auto' : 'mr-auto'"
        v-for="(message) in messages"
        :key="message.id"
      >
        <div
          class="p-2 border rounded-lg transition-all duration-300 hover:bg-opacity-50"
          :class="isLocal(message) ? 'bg-blue-300 bg-opacity-25' : 'bg-gray-300 bg-opacity-10'"
        >
          <div class="mb-1">
            <strong class="mr-1">{{ message.user.name }}</strong>
            <small>{{ appUtils.isToday(message.updated_at) ? appUtils.formatSimpleTime(message.updated_at) : appUtils.formatDateTime(message.updated_at) }}</small>
          </div>
          <div class="chat-content" v-html="message.html" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .chat-content {
    p:last-child {
      margin-bottom: 0;
    }
  }
}
</style>

<script>
import defaultImage from '@/assets/images/no-avt.png'
import appUtils from '@/utils/appUtils'

export default {
  name: 'ChannelDetail',
  props: {
    channel: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      appUtils,
      defaultImage,
      channelData: this.channel || {},
      isLoading: true,
      readInfo: null,
      userId: String(this.$user.id) || '',
      messages: [],
      members: []
    }
  },
  async beforeMount () {
    await this.getChannelMessage()
  },
  watch: {
    channel: {
      async handler (data) {
        this.channelData = { ...data }
        await this.getChannelMessage(data)
      }
    }
  },
  computed: {
    getChannelName () {
      return this.channelData?.data?.name || this.$t('No name')
    },
    getOwnerAvatar () {
      return this.channelData?.created_by?.image || ''
    },
    getOwnerName () {
      return this.channelData?.created_by?.name || ''
    },
    getOwnerIsOnline () {
      return this.channelData?.created_by?.online ? 'Online' : 'Offline'
    }
  },
  methods: {
    errorHandler () {
      return true
    },
    async debounce (fn, ms) {
      let timer

      return function () {
        const args = arguments
        const context = this

        if (timer) clearTimeout(timer)

        timer = setTimeout(() => {
          fn.apply(context, args)
        }, ms)
      }
    },
    async getChannelMessage (data) {
      const channelState = data || this.channelData || this.channel
      if (!channelState) return

      const state = channelState?.state
      this.readInfo = state?.read[this.userId]

      const lastMessageId = this.readInfo?.last_read_message_id
      const channel = this.$chatClient.channel(
        channelState?.type,
        channelState?.id
      )

      const result = await channel.query({
        messages: { limit: 1000, id_lt: lastMessageId },
        members: { limit: 20, offset: 0 },
        watchers: { limit: 20, offset: 0 }
      })

      this.messages = [...result.messages]
      this.members = [...result.members]
    },
    isLocal (data) {
      return data?.user?.id === this.userId
    }
  }
}
</script>

<style>
</style>
