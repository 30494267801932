<template>
  <div>
    <div>{{ getChannelName }}</div>
    <div class="flex items-center">
      <el-avatar class="mr-1" :size="32" :src="getOwnerAvatar" @error="errorHandler">
        <img :src="defaultImage" />
      </el-avatar>
      <div>
        <div>{{ getOwnerName }}</div>
        <div>{{ getOwnerIsOnline }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultImage from '@/assets/images/no-avt.png'

export default {
  name: 'ChannelCard',
  props: {
    channel: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      defaultImage,
      channelData: this.channel || {},
      isLoading: true
    }
  },
  watch: {
    channel: {
      handler (data) {
        this.channelData = { ...data }
        console.log(data)
      }
    }
  },
  computed: {
    getChannelName () {
      return this.channelData?.data?.name || this.$t('No name')
    },
    getOwnerAvatar () {
      return this.channelData?.created_by?.image || ''
    },
    getOwnerName () {
      return this.channelData?.created_by?.name || ''
    },
    getOwnerIsOnline () {
      return this.channelData?.created_by?.online ? 'Online' : 'Offline'
    }
  },
  methods: {
    errorHandler () {
      return true
    },
    async debounce (fn, ms) {
      let timer

      return function () {
        const args = arguments
        const context = this

        if (timer) clearTimeout(timer)

        timer = setTimeout(() => {
          fn.apply(context, args)
        }, ms)
      }
    }
  }
}
</script>

<style>
</style>
